<template>
  <div>
    <h2>コインの購入</h2>
    <b-card
      title=""
    >
      <b-form>
        <b-row
          v-for="(item, index) in menulist"
          :key="index"
        >
          <b-form-radio
            v-model="Selected"
            name="some-radio9"
            :value="item.num"
            class="custom-control-primary mb-2 ml-2"
          >
            {{ item.coinLabel }}({{ item.priceLabel }})
          </b-form-radio>
        </b-row>
        <b-row>
          <div class="errorMSG">
            {{ errorMSG }}
          </div>
        </b-row>
        <b-row class="mt-3">
          <b-col offset-md="4">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mr-1"
              @click="cancel"
            >
              キャンセル
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="confirm"
            >
              確認する
              <b-spinner
                v-if="status === 2"
                small
              />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BForm, BButton, BCard, BSpinner, BFormRadio,
} from 'bootstrap-vue'
import menulist from '@/components/conf/student/学習PF_受講生_コイン.json'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BCard,
    BSpinner,
    BFormRadio,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      inputForm: {},
      status: 1,
      isShow: false,
      Selected: '',
      menulist,
      errorMSG: '',
    }
  },
  computed: {
  },
  watch: {
    Selected: {
      handler() {
        // コインを選択したらエラーメッセージは消す→アラートに変更したら削除🔥
        if (this.errorMSG !== '' && this.Selected !== '') {
          this.errorMSG = ''
        }
      },
      immediate: true,
    },
  },
  mounted() {
  },
  methods: {
    confirm() {
      this.errorMSG = ''
      if (this.Selected === '') {
        this.errorMSG = '商品を選択してください。'
        return
      }
      this.$router.push({ path: '/student-pay', query: { id: this.Selected } })
    },
    cancel() {
      this.$router.push({ name: 'student-pay-list' })
    },
  },
}
</script>
<style scoped>
  .errorMSG {
    color: red;
    margin: 3% auto;
  }
</style>
